@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hexagon-clip-path {
    clip-path: url(#hex-shape-clip);
    -webkit-clip-path: url(#hex-shape-clip);
  }
}

.loader-white {
  border-bottom-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-red {
  border-bottom-color: #c11414;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-primary {
  border-bottom-color: #5a32f5;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-gray {
  border-bottom-color: #46505f;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-green {
  border-bottom-color: #007859;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Pride BG */
.rounded-pride-border {
  background: linear-gradient(
      to right,
      #000000,
      #784f16,
      #e40303,
      #ff8c00,
      #ffed00,
      #008026,
      #004dff,
      #750787,
      #5ccefa,
      #f6a8b7,
      #ffffff
    )
    border-box;
}

.pride-switch {
  background: linear-gradient(
    89.84deg,
    #000000 0%,
    #784f16 11.6%,
    #e40303 22.84%,
    #ff8c00 31%,
    #ffed00 39.34%,
    #008026 48.94%,
    #004dff 57.65%,
    #870731 69.85%,
    #5ccefa 84.53%,
    #f6a8b7 100%
  );
}

.pride-border {
  border-image: linear-gradient(
      45deg,
      #000000,
      #784f16,
      #e40303,
      #ff8c00,
      #ffed00,
      #008026,
      #004dff,
      #750787,
      #5ccefa,
      #f6a8b7,
      #ffffff
    )
    1;
}

.text-rendering {
  text-rendering: optimizeLegibility;
}

.remove-slider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  opacity: 0;
  cursor: pointer;
}

.misfans-slider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 17px;
  height: 17px;
  border-radius: 9999px;
  background: #542ae2;
  cursor: pointer;
}

.misfans-slider-thumb::-webkit-slider-thumb:hover {
  background: #401bbc;
}
