
.hamburguesa{
  display:flex;
  flex-direction:column;
  width:24px;
  cursor:pointer;
}

.hamburguesa span{
  background: #542AE2; 
  border-radius:10px;
  height:4px;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
}


.hamburguesa span:nth-of-type(1){
  width:50%;
  
}

.hamburguesa span:nth-of-type(2){
  width:100%;
  margin: 6px 0px;
}


.hamburguesa span:nth-of-type(3){
  width:75%;
 
}


.openState1{
  transform-origin:bottom;
  transform:rotatez(45deg) translate(4.7px,2.1px)
}


.openState2{
  
  transform-origin:top;
  transform:rotatez(-45deg)
}


.openState3 {
  
  transform-origin:bottom;
  width:50%;
  transform: translate(5px,-9px) rotatez(45deg);

}
