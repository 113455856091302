.iw_skeleton {
  background: #222222;
}

.iw_skeleton-text {
  width: 100%;
  height: 1rem;
  background: #222222;
}

.iw_skeleton-light {
  background: #e8e8ff;
}

.iw_skeleton-text-light {
  width: 100%;
  height: 1rem;
  background: #e8e8ff;
}
